<template>
  <div class="main">
    <div class="header">
      <div class="banner">
        <img src="~/static/activity/jobFair-country/banner.png" alt="" title="高校残疾人毕业生就业就业帮扶">
      </div>
      <div class="nav">
        <div class="tab">
          <li v-for="(item,index) in nav" :key="index" @click="tabs(index)" :class="active == index?'active':''">
            {{ item }}
          </li>
        </div>
      </div>
    </div>
    <div class="content">
      <ul class="jobFair_list" v-if="false">
        <li @click="goRouter('')">
          深圳招聘会专场
          <img src="~/static/activity/jobFair-country/return.png" alt="">
        </li>
        <li @click="goRouter('')">
          深圳招聘会专场
          <img src="~/static/activity/jobFair-country/return.png" alt="">
        </li>
      </ul>
      <section class="section1" id="section0">
        <div class="title">
          <img src="~/static/activity/jobFair-country/job_title.png" alt="">
        </div>
        <ul class="select">
          <li>
            <span>
              选择地区：
            </span>
            <ThreeLink
                codeType="provinceid"
                :codeLevel="'3'"
                placeholder="不限地区"
                class="three"
                size="mini"
                :show_level="false"
                @setMValue="setScasValue($event,form,['provinceid','cityid','threeCityid'])"
                :defaultValue="[form.addressProvinceid,form.addressCityid,form.addressThreeid]"
            ></ThreeLink>
          </li>
          <li>
            <span>
              残疾类别：
            </span>
            <MulSelect
                code="disabled_type"
                class="three"
                size="mini"
                placeholder="不限类别"
                @setMValue="setSelectValue($event,'disType')"
                :defaultValue="form.disType"
            ></MulSelect>
          </li>
          <li>
            <span>
              选择行业：
            </span>
            <ThreeLink
                codeType="hy1"
                codeLevel="2"
                placeholder="不限行业"
                size="mini"
                class="three"
                :show_level="false"
                @setMValue="setScasValue($event,form,['hyTop','hy'])"
                :defaultValue="[form.hyTop,form.hy]"
            ></ThreeLink>
          </li>
          <li>
            <span>
              月薪范围：
            </span>
            <MulSelect
                code="user_salary"
                class="three"
                size="mini"
                placeholder="月薪范围"
                @setMValue="setSelectValue($event,'jobSalary')"
                :defaultValue="form.jobSalary"
            ></MulSelect>
          </li>
          <li>
            <span>
              公司性质：
            </span>
            <MulSelect
                code="pr"
                class="three"
                size="mini"
                placeholder="不限性质"
                @setMValue="setSelectValue($event,'pr')"
                :defaultValue="form.pr"
            ></MulSelect>
          </li>
          <li class="search">
            <el-input v-model="form.name" placeholder="请输入职位/公司名称" size="mini"></el-input>
            <el-button type="primary" size="mini">搜索</el-button>
          </li>
        </ul>
        <div class="section_content">
          <div class="more">
            <el-button type="primary" class="btn" @click="reset" size="mini">重置</el-button>
            <a :href="'/videoJob?id='+form.id" target="_blank">
              全部职位>>
            </a>
          </div>
          <job :job-list="jobList"></job>
          <status v-show="jobList.length <= 0"></status>
        </div>
      </section>
      <section class="section2" id="section1">
        <div class="title">
          <img src="~/static/activity/jobFair-country/title2.png" alt="">
        </div>
        <ul class="tab">
          <li @click="getTabChild(0)" :class="actives == 0?'active':''">
            直播带岗
          </li>
          <li @click="getTabChild(1)" :class="actives == 1?'active':''">
            直播培训
          </li>
        </ul>
        <zhibo :list="zhiboList" :active="actives"></zhibo>
      </section>
      <ad src="/ad.jpg" url="https://www.cdpee.org.cn/videoJob?id=f943fa520839bea66ea9a76e726f6aa3&name=2022陕西省残疾人就业创业网络服务平台就业援助年网络招聘会&provinceid&cityid"></ad>
      <section class="section3" id="section2">
        <div class="title">
          <img src="~/static/activity/jobFair-country/title3.png" alt="">
        </div>
        <div class="more">
          <a :href="'/videoJob?id='+form.id+'&active=1'" target="_blank">
            全部企业>>
          </a>
        </div>
        <company :list="companyList"></company>
      </section>
      <section class="section4" id="section3">
        <div class="title">
          <img src="~/static/activity/jobFair-country/title4.png" alt="">
        </div>
        <div class="more">
          <div class="study" @click="isCompany('public')">
            您还想学什么呢?
          </div>
          <a :href="'/videoJob?id='+form.id+'&active=2'" target="_blank">
            全部课程>>
          </a>
        </div>
        <Public :list="publicList"></Public>
      </section>
      <ad src="/ad1.png" url="https://www.cdpee.org.cn/job/"></ad>
      <section class="section5" id="section4">
        <div class="title">
          <img src="~/static/activity/jobFair-country/title5.png" alt="">
        </div>
        <div class="more">
          <ThreeLink
              codeType="provinceid"
              :codeLevel="'2'"
              placeholder="不限地区"
              class="three"
              size="mini"
              :show_level="false"
              @setMValue="setScasValue($event,newSelect,['provinceid','city'])"
          ></ThreeLink>
          <a href="/activity/policy" target="_blank">
            全部新闻>>
          </a>
        </div>
        <news :list="newList"></news>
      </section>
      <section class="section6" id="section5">
        <div class="title">
          <img src="~/static/activity/jobFair-country/title6.png" alt="">
        </div>
        <guide></guide>
      </section>
    </div>
    <ul class="fixed">
      <li @click="isCompany">
        企业参<br/>
        会报名
      </li>
      <li @click="isShow1 = true">
        联系<br/>
        客服
      </li>
      <li @click="top">
        返回<br/>
        顶部
      </li>
    </ul>
    <el-dialog
        :visible.sync="isShow1"
        width="324px"
        :lock-scroll="false"
        :before-close="onClose">
      <div class="qr">
        <img src="~/static/zhibo.png" alt="">
        <h3>请添加企业微信 <br/>
          获取更多服务</h3>
      </div>
    </el-dialog>
    <company-sign :show="isShow" :id="form.id" @close="onClose"></company-sign>
    <login-prompt :goShow="isHide" @close="onClose" title="企业报名" text="登录后才可以进行企业报名"></login-prompt>
    <el-dialog
        :visible.sync="isShow2"
        title="反馈"
        width="600px"
        :lock-scroll="false"
        :before-close="onClose">
      <Needs @close="onClose"></Needs>
    </el-dialog>
  </div>
</template>

<script>
import MulSelect from "@/components/selectComponents/MulSelect";
import ThreeLink from "@/components/selectComponents/ThreeLink";
import job from "./components/job";
import zhibo from "./components/zhibo";
import ad from "./components/ad";
import company from "./components/company";
import Public from "./components/public";
import news from "./components/new";
import Needs from "./components/Needs";
import status from "@/components/public/status.vue"
import Guide from './components/guide.vue';
import loginPrompt from '@/components/public/loginPrompt'
import companySign from "components/public/companySign";
export default {
  name: "index",
  components: {
    MulSelect,
    ThreeLink,
    job,
    zhibo,
    ad,
    company,
    Public,
    news,
    Needs,
    status,
    Guide,
    loginPrompt,
    companySign
  },
  data() {
    return {
      active: 0,
      actives: 0,
      value: '',
      form: {
        name: '',
        cityid: '',
        threeCityid: '',
        disType: '',
        jobSalary: '',
        pr: '',
        id: 'd86a2a9e8b147baf59b6dfeea115519a',
        pageNum: 1,
        pageSize: 9,
        temp:new Date().getTime()
      },
      nav: ['职位推荐', '直播活动', '优选企业', '就业公开课', '就业政策', '就业指导'],
      city: '',
      jobList: [],
      zhiboList: [],
      companyList: [],
      newList: [],
      publicList: [],
      cityList: [],
      pages: {
        pageNum: 1,
        pageSize: 3,
        zhiboType: 1,
        timeStatus: '',
        temp:new Date().getTime()
      },
      newSelect: {
        leve: '全国',
        city: '',
        pageNum: 1,
        pageSize: 10,
        consultationClassName: '就业指导',
      },
      isShow: false,
      isShow1: false,
      isShow2:false,
      isHide: false,
      isLogin: '',
    }
  },
  watch: {
    form: {
      handler(newName, oldName) {
        this.getJob();
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getJob();
      this.zhibo();
      this.company();
      this.getPublic();
      this.cityAll();
      this.news();
      this.isLogin =
          this.$store.state.userType ||
          JSON.parse(localStorage.getItem("userType"));
    },
    tabs(i) {
      this.active = i;
      window.location.href = '#section' + i;
    },
    getTabChild(i) {
      this.actives = i;
      if (i == 0) {
        this.pages.pageSize = 3;
        this.pages.zhiboType = 1;
        this.pages.timeStatus = '';
      } else {
        this.pages.pageSize = 2;
        this.pages.zhiboType = 2;
        this.pages.timeStatus = 3;
      }
      this.zhibo();
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    //清空
    reset() {
      for (let key in this.form) {
        if (typeof this.form[key] === "string") {
          this.form[key] = "";
        }
      }
      this.form.id = 'd86a2a9e8b147baf59b6dfeea115519a';
    },
    //三级联动
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
        this.news();
      }
    },

    //单选
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    //职位筛选
    jobChange(val) {
      this.getJob();
    },
    //城市
    async cityAll() {
      let res = await this.$api.cityAllApi();
      this.cityList = res.data;
    },
    //推荐职位
    async getJob() {
      let res = await this.$api.jobFairJobs(this.form);
      if (res.data.success) {
        this.jobList = res.data.data.records;
      }
    },
    //直播
    async zhibo() {
      let res = await this.$api.zhiboActivityListApi(this.pages);
      if (res.data.success) {
        this.zhiboList = res.data.data.records;
      }
    },
    //  公司
    async company() {
      let res = await this.$api.jobFairCompanys({
        id: this.form.id,
        pageNum: 1,
        pageSize: 9
      });
      if (res.data.success) {
        this.companyList = res.data.data.records;
      }
    },
    //  公开课
    async getPublic() {
      let res = await this.$api.getHomeOpenCourseApi({
        pageNum: 1,
        pageSize: 8,
        projectType: 1,
      });
      if (res.data.success) {
        this.publicList = res.data.data;
      }
    },
    //新闻
    async news() {
      let res = await this.$api.newsListApi(this.newSelect);
      if (res.data.success) {
        this.newList = res.data.data.records;
      }
    },
    top() {
      window.scrollTo(0, 0);
    },
    //企业报名
    isCompany(type) {
      if (this.isLogin) {
        if(type == 'public'){
          this.isShow2 = true;
        }else{
          this.isShow = true;
        }
      } else {
        this.isHide = true;
      }
    },
    onClose() {
      this.isShow = false;
      this.isShow1 = false;
      this.isShow2 = false;
      this.isHide = false;
    },
  },
}
</script>

<style scoped lang="less">
.main {
  background: #FFFAF3;

  .header {
    //flex-direction: column;
    .banner {
      display: flex;

      img {
        width: 100%;
      }
    }

    .nav {
      width: 100%;
      background: #FAD49E;

      .tab {
        width: 1200px;
        height: 72px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
          position: relative;
          font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          cursor: pointer;
          color: rgba(0, 18, 10, .6);
        }

        .active {
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
        }

        .active:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -15px;
          transform: translateX(-50%);
          width: 36px;
          height: 4px;
          background: #D71216;
          border-radius: 3px;
        }
      }
    }
  }

  .content {
    width: 1200px;
    margin: 0 auto;

    .jobFair_list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 60px;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 282px;
        height: 72px;
        background: #D71216;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FAD49E;
        letter-spacing: 1px;
        margin-right: 24px;
        cursor: pointer;

        img {
          margin-left: 18px;
        }
      }

      li:last-child {
        margin-right: 0;
      }
    }

    section {
      .title {
        padding: 60px 0;
      }

      .more {
        text-align: right;

        .study {
          width: 150px;
          height: 42px;
          line-height: 42px;
          display: inline-block;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #D71216;
          background: #FCDEB1;
          text-align: center;
          margin-right: 12px;
          cursor: pointer;
        }

        .btn {
          margin-right: 24px;
        }

        a {
          color: #66716C;
        }
      }

      .more a:hover {
        color: #D71216;
        text-decoration: underline;
      }
    }

    .section1 {
      .title {
        padding-bottom: 0;
      }

      .select {
        margin: 36px 0;
        display: flex;
        justify-content: space-between;

        li {
          display: flex;
          align-items: center;

          span {
            color: #66716C;
          }


        }

        .search {
          /deep/ .el-input {
            width: 168px;
            margin-right: 6px;

            .el-input__inner {
              background: transparent;
            }

            input::placeholder {
              color: #66716C;
            }
          }
        }
      }

      .section_content {

      }
    }

    /deep/ .three {
      width: 96px;

      .el-input {
        .el-input__inner {
          background: transparent;
        }
      }

      input::placeholder {
        color: #66716C;
      }
    }

    .section2 {
      .title {
        padding: 60px 0;
      }

      .tab {
        display: flex;
        justify-content: center;
        margin-bottom: 28px;

        li {
          margin-right: 98px;
          font-size: 26px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 17, 8, .6);
          cursor: pointer;
        }

        li:last-child {
          margin-right: 0;
        }

        li.active {
          position: relative;
          font-size: 26px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #001108;
        }

        li.active::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -15px;
          transform: translateX(-50%);
          width: 36px;
          height: 4px;
          background: #D71216;
          border-radius: 3px;
        }
      }
    }

    .section5 {
      position: relative;

      .new {
        position: relative;
      }

      .title {
        padding-top: 0;
      }

      .three {
        margin-right: 24px;
      }

      .more {
        position: absolute;
        top: 145px;
        right: 0;
        z-index: 11;
      }
    }

    .section6 {
      .title {
        margin: 120px 0 48px 0;
        padding: 0;
      }
    }
  }

  .fixed {
    position: fixed;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);

    li {
      width: 66px;
      height: 66px;
      background: #D71216;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FAD49E;
      line-height: 22px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
      cursor: pointer;
    }
  }

  .qr {
    text-align: center;

    img {
      width: 180px;
      height: 180px;
    }
  }
}
</style>