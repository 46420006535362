<template>
  <div class="public">
    <ul class="list">
      <li
          v-for="item in list"
          :key="item.id"
          @click="goRouter('/training/publicDetail', item.id)"
      >
        <div class="banner">
          <img :src="item.imgurl" :alt="item.trainName" />
        </div>
        <div class="bottom">
          <div class="name line_clamp2">{{ item.trainName }}</div>
          <div class="key" v-if="item.keyWord">
            {{ item.keyWord }}
          </div>
          <div class="hot">
            <img src="~/static/home/hot.png" alt="热度"/>{{ item.hits }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "public",
  props: ["list"],
  methods:{
    goRouter(url, id) {
      this.clicks(id);
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    //点击量
    clicks(id) {
      this.$api.publicClickApi({ id: id }).then((res) => {});
    },
  },
}
</script>

<style scoped lang="less">
  .public{
    .list {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 282px;
        height: 324px;
        margin-right: 24px;
        border-radius: 6px;
        overflow: hidden;
        transition: all 0.6s;
        background: white;
        border: 1px solid rgba(0, 18, 10, 0.05);
        margin-top: 24px;
        cursor: pointer;
        .banner {
          width: 100%;
          height: 210px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .bottom {
          position: relative;
          height: 114px;
          padding: 12px;
          &:hover {
            .name{
              color: #28a46d;
            }
          }
          .name {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #00120a;
            height: 23px;
            overflow: hidden;
          }
          .hot {
            position: absolute;
            left: 12px;
            bottom: 12px;
            img {
              vertical-align: middle;
              transform: translateY(-1px);
            }
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #e60020;
          }
        }
      }
      li:nth-of-type(4n) {
        margin-right: 0;
      }
      li:hover {
        transform: translateY(-6px);
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
      }
    }
  }
</style>