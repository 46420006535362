<template>
  <div class="child">
    <ul class="zhibo">
      <li v-for="item in list" :key="item.id">
        <div class="banner">
          <img :src="item.logo" alt="" class="err_image">
          <div class="status green" v-if="item.timeStatus == 0">
            正在直播
          </div>
          <div class="status org" v-if="item.timeStatus == 1">
            直播预告
          </div>
          <div class="status gray" v-if="item.timeStatus == 2">
            直播结束
          </div>
        </div>
        <div class="bottom bottom1" v-if="item.timeStatus == 0">
          <h3 class="line_clamp1">{{item.titile}}</h3>
          <div class="people">
            主讲老师：{{ item.perName }}
          </div>
          <a :href="item.url" target="_blank" class="btn red" v-if="item.timeStatus == 0">
            观看直播
          </a>
          <a :href="item.url" target="_blank" class="btn org" v-if="item.timeStatus == 2">
            查看回放
          </a>
        </div>
        <div class="bottom bottom2" v-else>
          <div class="left">
            <h3 class="line_clamp1">{{item.titile}}</h3>
            <div class="people">
              主讲老师：{{ item.perName }}
            </div>
            <div class="time">
              直播时间：{{item.time}}
            </div>
            <div class="tip">
              扫描右方二维码预约观看>>
            </div>
          </div>
          <div class="right">
            <img :src="item.src" alt="" class="err_image">
          </div>
        </div>
      </li>
    </ul>
    <ul class="zhibo swiper" v-if="active == 1">
      <li v-for="item in list1.slice(0,4)" :key="item.id">
        <div class="banner">
          <img :src="item.logo" alt="" class="err_image">
          <div class="status gray">
            直播结束
          </div>
        </div>
        <div class="bottom bottom1">
          <h3 class="line_clamp1">{{item.titile}}</h3>
          <a :href="item.url" target="_blank" class="btn org">
            查看回放
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "zhibo",
  props: ["list","active"],
  data(){
    return{
      list1:[],
      pages:{
        pageNum:1,
        pageSize:10,
        timeStatus:2
      },
      swiperOption: {
        // loop : true,
        autoplay: {
          autoplay: true,
          disableOnInteraction: false
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        lazy: {
          loadPrevNext: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  watch:{
    active:function (val){
      if (val == 1){
        this.info();
      }
    }
  },
  methods:{
   async info(){
      let res = await this.$api.zhiboActivityListApi(this.pages);
      if (res.data.success) {
        this.list1 = res.data.data.records;
      }
    },
    swiper() {
      new Promise((resolve, reject) => {
        if (this.$refs.mySwiper){
          resolve(this.$refs.getSwiper.swiper);
        }else{
          reject('swiper有问题，请检查！')
        }
      })
    },
    onSwiper() {

    },
    onSlideChange() {

    },
    goRouter(url, id,) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
  },
}
</script>

<style scoped lang="less">
  .child{
    .zhibo{
      display: flex;
      justify-content: center;
      li{
        width: 385px;
        height: 416px;
        margin-right: 23px;
        border: 1px solid white;
        background: rgba(255, 255, 255, 0.5);
        .banner{
          position: relative;
          img{
            width: 100%;
            height: 288px;
          }
          .status{
            position: absolute;
            top: 12px;
            right: 12px;
            width: 72px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            background: rgba(0, 18, 10, 0.6);
          }
          .green{
            color: rgba(54, 255, 166, .8);
          }
          .org{
            color: rgba(255, 180, 21, .8);
          }
          .gray{
            color: rgba(255, 255, 255, 0.8);
          }
        }
        .bottom{
          position: relative;
          height: 128px;
          padding: 12px;
          h3{
            font-size: 18px;
          }
          .people{
            color: #66716C;
            line-height: 28px;
          }
          .btn{
            position: absolute;
            right: 18px;
            bottom: 18px;
            width: 78px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            cursor: pointer;
          }
          .red{
            color: #FFFFFF;
            background: #D71216;
          }
          .org{
            color: #66716C;
            background: #FAD49E;
          }
        }
        .bottom2{
          display: flex;
          .left{
            flex: 1;
            .people,.time.tip{
              line-height: 22px;
            }
            .tip{
              color: #D71216;
              margin-top: 10px;
            }
          }
          .right{
            margin-left: 18px;
            img{
              width: 96px;
              height: 96px;
            }
          }
        }
      }
      li:last-child{
        margin-right: 0;
      }
    }
    .swiper{
      margin-top: 24px;
      li{
        width: 282px;
        height: 330px;
        margin-right: 23px;
        border: 1px solid white;
        background: rgba(255, 255, 255, 0.5);
        .banner{
          position: relative;
          img{
            width: 100%;
            height: 210px;
          }
          .status{
            position: absolute;
            top: 12px;
            right: 12px;
            width: 72px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            background: rgba(0, 18, 10, 0.6);
          }
          .green{
            color: rgba(54, 255, 166, .8);
          }
          .org{
            color: rgba(255, 180, 21, .8);
          }
          .gray{
            color: rgba(255, 255, 255, 0.8);
          }
        }
        .bottom{
          position: relative;
          height: 120px;
          padding: 12px;
          h3{
            font-size: 18px;
            line-height: 28px;
          }
          .people{
            color: #66716C;
            line-height: 28px;
          }
          .btn{
            position: absolute;
            right: 18px;
            bottom: 18px;
            width: 78px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            cursor: pointer;
          }
          .red{
            color: #FFFFFF;
            background: #D71216;
          }
          .org{
            color: #66716C;
            background: #FAD49E;
          }
        }
        .bottom2{
          display: flex;
          .left{
            flex: 1;
            .people,.time.tip{
              line-height: 22px;
            }
            .tip{
              color: #D71216;
              margin-top: 10px;
            }
          }
          .right{
            margin-left: 18px;
            img{
              width: 96px;
              height: 96px;
            }
          }
        }
      }
    }
  }
</style>