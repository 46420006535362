<template>
  <div class="ad">
    <a :href="url" target="_blank">
      <el-image
          :src="require('@/static/activity/jobFair-country/ad'+ src)"
      >
        <div slot="placeholder" class="image-slot">
          加载中<span class="dot">...</span>
        </div>
        <div slot="error" class="image-slot">
          <img src="~/static/lhjy1.png" class="banner_err" alt="">
        </div>
      </el-image>
    </a>
  </div>
</template>

<script>
export default {
  name: "ad",
  props:['id','src','url'],
  data(){
    return{

    }
  },
  methods:{
    async info(){

    },
  },
  created() {
  }
}
</script>

<style scoped lang="less">
  .ad{
    margin: 120px 0;
    img{
      width: 100%;
      height: 96px;
    }
  }
</style>