<template>
  <div class="guide">
    <div class="content">
      <div class="expert">
        <div class="title">
          <h2>专家答疑</h2>
          <a href="/activity/employment-guidance/">全部专家>></a>
        </div>
        <ul>
          <li v-for="(item, index) in expertList" :key="index">
            <img :src="item.headSrc" alt="" />
            <div class="text">
              <div class="top">
                <p class="name">{{ item.name }}</p>
                <button @click="open()">我要咨询</button>
              </div>
              <span>
                {{ item.content }}
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div class="problem">
        <div class="title">
          <h2>常见问题</h2>
          <div class="small">
            <span @click="askProblem()">去提问</span>
            <span @click="goRouter('/activity/employment-guidance')"
              >全部问题>></span
            >
          </div>
        </div>
        <ul>
          <li
            v-for="(item, indexs) in problemList"
            :key="indexs"
            @click="goRouter('/asklib/askDetail', item.id)"
          >
            <h3>{{ item.askTitle }}</h3>
            <p class="line_clamp1">{{ item.content }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="dialog">
      <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="324px"
        :before-close="handleClose"
      >
        <img src="~/static/zhibo.png" alt="" />
        <p class="p1">就业疑惑，听听专家怎么说</p>
        <p class="p2">请您添加企业微信<br />并备注：加入就业资讯1群</p>
      </el-dialog>
    </div>
    <div class="dialogAsk">
      <el-dialog
        title="我要提问"
        :visible.sync="AskdialogVisible"
        width="629px"
        :before-close="handleClose"
      >
        <div class="dialog_content">
          <!-- <img :src="dialog.avatar" alt="" class="img err_avatar" /> -->
          <el-form ref="form" :model="form" :rules="err" class="inputs">
            <el-form-item prop="askTitle">
              <el-input
                type="text"
                v-model="form.askTitle"
                placeholder="请输入问题标题"
                class="name"
              ></el-input>
            </el-form-item>
            <div class="hide" v-if="form.askTitle.length">
              <el-form-item prop="content">
                <el-input
                  type="textarea"
                  rows="5"
                  v-model="form.content"
                  placeholder="详细描述您的问题"
                  resize="none"
                  maxlength="300"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <div class="upload">
                <el-upload
                  class="avatar-uploader"
                  action="/api/app-jycy-disabled/uploadImg"
                  list-type="picture"
                  :headers="uploadHeaders"
                  :file-list="listImage"
                  :limit="3"
                  :on-success="handlePreview"
                  :on-remove="handleRemove"
                  :before-upload="beforeAvatarUpload"
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="插入图片"
                    placement="bottom-start"
                  >
                    <i class="el-icon-picture-outline avatar-uploader-icon"></i>
                  </el-tooltip>
                </el-upload>
              </div>
            </div>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-checkbox v-model="form.isAnonymity">匿名</el-checkbox>
          <el-button
            type="primary"
            @click="addQuestion"
            :disabled="form.askTitle.length ? false : true"
            v-loading="loadings"
            >发布
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "new",
  props: ["list"],
  data() {
    return {
      expertList: [],
      problemList: [],
      dialogVisible: false,
      AskdialogVisible: false,
      loadings: false,
      listImage: [],
      page: {
        pageNum: 0,
        pageSize: 10,
        topicId: "690deeff008f0b069f2846e641699d97",
      },
      form: {
        askTitle: "",
        content: "",
        isAnonymity: 0,
        pic1: "",
        topicId: "690deeff008f0b069f2846e641699d97",
      },
      err: {
        askTitle: [
          { required: true, message: "提问问题不能为空", trigger: "blur" },
        ],
        content: [
          { required: true, message: "详情描述不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.AskdialogVisible = false;
    },
    goRouter(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    getExpert() {
      this.$axios
        .get("/api/app-jycy-activity/getGuopinExpert?pageSize=20&pageNum=1")
        .then((res) => {
          this.expertList = res.data.data.records;
        });
    },
    getProblem() {
      this.$api.askListApi(this.page).then((res) => {
        this.problemList = res.data.data.records;
      });
    },
    askProblem() {
      this.AskdialogVisible = true;
    },
    //添加问题
    addQuestion() {
      this.form.pic1 = JSON.stringify(this.listImage);
      if (this.form.isAnonymity) {
        this.form.isAnonymity = 1;
      } else {
        this.form.isAnonymity = 0;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadings = true;
          this.$api.addQuestionApi(this.form).then((res) => {
            this.loadings = false;
            if (res.data.success) {
              this.$message.success(res.data.msg);
              setTimeout(() => {
                this.AskdialogVisibledialogVisible = false;
              }, 1000);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    handlePreview(file, fileList) {
      this.listImage.push(file.msg);
    },
    handleRemove(file) {
      this.listImage.pop(file.response.msg);
    },
    beforeAvatarUpload(file) {
      let type = ["image/jpeg", "image/png"];
      const isImage = type.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error("上传图片格式不正确");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isImage && isLt2M;
    },
  },
  created() {
    this.getExpert();
    this.getProblem();
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.content {
  display: flex;
}
h2 {
  font-size: 26px;
  font-weight: 600;
  color: #001108;
  line-height: 37px;
  letter-spacing: 1px;
  position: relative;
  text-align: left;
  margin: 0 0 34px 0;
  &::after {
    width: 36px;
    height: 4px;
    background: #d71216;
    border-radius: 3px;
    position: absolute;
    bottom: -6px;
    left: 0;
    content: "";
  }
}

.expert {
  flex: 1;
  .title {
    display: flex;
    justify-content: space-between;
    margin-right: 24px;
    a {
      font-weight: 600;
      color: #66716c;
      line-height: 22px;
      transform: translateY(8px);
      &:hover{
        text-decoration: underline;
        color: #dc1217;
      }
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      padding: 18px;
      width: 588px;
      height: 150px;
      background: rgba(255, 255, 255, 0.5);
      border: 2px solid #ffffff;
      display: flex;
      margin-bottom: 24px;
      &:hover {
        border: 2px solid #fad49e;
      }
      > img {
        width: 114px;
        height: 114px;
        margin-right: 18px;
        box-shadow: 0px 20px 15px 0px rgba(117, 77, 20, 0.04);
        border-radius: 50%;
      }
      .text {
        flex: 1;
        .top {
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          p {
            font-size: 18px;
            font-weight: 600;
            color: #00120a;
            line-height: 25px;
            letter-spacing: 1px;
          }
          button {
            width: 78px;
            height: 33px;
            background: #d71216;
            color: #fbd9a8;
            &:hover {
              cursor: pointer;
            }
          }
        }
        span {
          color: #66716c;
          line-height: 24px;
          letter-spacing: 1px;
        }
      }
    }
  }
}
.problem {
  flex: 1;
  .title {
    display: flex;
    justify-content: space-between;
    .small {
      transform: translateY(8px);
      > :first-child {
        color: #dc1217;
        cursor: pointer;
        text-decoration: underline;
      }
      span {
        font-weight: 600;
        color: #66716c;
        line-height: 22px;
        cursor: pointer;
        margin-left: 12px;
        &:hover{
          text-decoration: underline;
        color: #dc1217;
        }
      }
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #ffffff;
    li {
      width: 549px;
      padding: 18px 0 18px 18px;
      position: relative;
      height: 99px;
      &::after {
        width: 552px;
        height: 1px;
        color: red;
        background: rgba(0, 18, 10, 0.05);
        position: absolute;
        content: "";
        bottom: 0px;
        right: -18px;
      }
      h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        color: #000f06;
        cursor: pointer;
        margin-bottom: 12px;
        &:hover {
          color: #d71216;
          // border-bottom: 1px solid #d71216 ;
        }
      }
      p {
        color: #66716c;
        line-height: 22px;
        letter-spacing: 0.67px;
      }
    }
  }
}
.dialog {
  text-align: center;
  img {
    width: 180px;
    height: 180px;
    margin-bottom: 24px;
  }
  .p1 {
    font-size: 18px;
    font-weight: 600;
    color: #001108;
    line-height: 25px;
    margin-bottom: 6px;
  }
  .p2 {
    color: #66716c;
    width: 191px;
    display: inline-block;
    line-height: 22px;
  }
}
/deep/ .dialog_content {
  width: 590px;
  .img {
    width: 44px;
    height: 44px;
    margin-right: 25px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  .inputs {
    flex: 1;

    .name {
      margin-bottom: 20px;
    }

    .hide {
      .el-upload {
        margin-top: 10px;

        .avatar {
          width: 100px;
          height: 100px;
        }

        i {
          font-size: 18px;
          color: #333333;
        }
      }
    }
  }
}
</style>
