<template>
  <div class="job">
    <ul class="job_li flex flex-wrap">
      <li v-for="item in jobList" :key="item.id" class="flex">
        <div class="top">
          <router-link
              :to="'/job/jobDetail?id=' + item.jobId"
              target="_blank"
              class="line_clamp1"
              :title="item.jobName"
          >
            <div class="flex nameWrap">
              <div class="name relative line_clamp1">
                {{ item.jobName }}
              </div>
              <p class="label" v-if="item.isPressing == '1'">
                急聘
              </p>
              <p class="money">{{ item.jobSalary || "面议" }}</p>
            </div>
            <div class="address line_clamp1">
                <span v-if="item.cityid">
                  {{ item.cityid }}<span v-if="item.threeCityid">-{{ item.threeCityid }} </span>
                </span>
              <span v-else> 城市不限 </span>
              <el-divider direction="vertical"></el-divider>
              <span v-if="item.edu">
                  {{ item.edu }}
                </span>
              <span v-else>学历不限</span>
              <el-divider direction="vertical"></el-divider>
              <span v-if="item.exp">
                  {{ item.exp }}
                </span>
              <span v-else>经验不限</span>
              <el-divider direction="vertical"></el-divider>
              <span v-if="item.jobDisableType.length > 2">
                  <span>多种残疾</span>
                </span>
              <span
                  v-if="item.jobDisableType.length < 2 && item.jobDisableType.length != 0"
              >
                  <span v-for="(n, i) in item.jobDisableType.slice(0, 2)" :key="i">
                    {{ n.disType }}
                    <em v-if="n.disTypeLevel">/</em>
                    {{ n.disTypeLevel }}
                  </span>
                </span>
            </div>
            <div class="welfare line_clamp1">
                <span
                    v-if="item.jobLabels == null || item.jobLabels.length <= 0"
                >暂无福利标签</span
                >
              <span
                  v-for="(n, i) in item.jobLabels.slice(0, 3)"
                  :key="i"
                  v-else
              >{{ n.labelName }}</span
              >
            </div>
          </router-link>
        </div>
        <div
            class="bottom flex cursorP"
            @click="
              goRouter('/job/businessDetails', '', item.companyInfo.id)
            "
        >
          <img
              :src="item.companyInfo.logo || ''"
              :alt="item.companyInfo.companyName"
              :title="item.companyInfo.companyName"
              class="logo err_logo"
              style="overflow:hidden"
          />

          <div class="info">
            <h3 class="line_clamp1">
              {{ item.companyInfo.companyName }}
            </h3>
            <div class="type">
                <span>
                  {{ item.companyInfo.pr || "暂无企业性质" }}
                </span>
              <el-divider
                  direction="vertical"
                  v-if="item.companyInfo.pr"
              ></el-divider>
              <span>
                  {{ item.companyInfo.hyTop }}
                </span>
              <el-divider
                  direction="vertical"
                  v-if="item.companyInfo.hyTop"
              ></el-divider>
              <span>
                  {{ item.companyInfo.mun }}
                  <i></i>
                </span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "job",
  props: ["jobList"],
  methods:{
    goRouter(url, id, companyId) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
          companyId: companyId
        },
      });
      window.open(href, "_blank");
    },
  },
}
</script>

<style scoped lang="less">
@import "~assets/css/public.less";
.job{
  .job_li {
    margin-top: 12px;

    li {
      position: relative;
      margin-top: 24px;
      margin-right: 24px;
      flex-direction: column;
      width: 384px;
      height: 192px;
      border: 2px solid #FFFAF3;
      border-radius: 6px;
      cursor: pointer;
      transition: all .3s;
      background: rgba(255, 255, 255, 0.5);
      .top {
        margin: 0 18px;
        flex: 1;

        a {
          max-width: 386px;
          float: left;
          width: 100%;
        }

        .nameWrap {
          width: 100%;
          padding-top: 18px;
          //justify-content: space-between;
        }

        .name {
          font-size: 18px;
          max-width:70%;
          font-weight: 600;
        }

        .label {
          width: 36px;
          height: 19px;
          background: #E60020;
          border-radius: 4px;
          transform: translateY(1px);
          margin-left: 3px;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 19px;
        }

        .money {
          position: absolute;
          right: 20px;
          color: #ff5500;
          font-size: 16px;
          font-weight: bold;
        }

        .address {
          padding-top: 9px;
          height: 30px;

          span {
            position: relative;
            color: #666666;
          }

          .el-divider--vertical:last-child {
            display: none;
          }
        }

        .welfare {
          height: 45px;
          padding: 9px 0 11px 0;
          border-bottom: 1px solid rgba(0, 18, 10, 0.05);

          span {
            display: inline-block;
            font-size: 12px;
            padding: 0 8px;
            margin-right: 12px;
            line-height: 22px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 6px;
            color: #28A46D;
            background: #f9f9f9;
          }
        }
      }

      .bottom {
        height: 64px;
        margin-top: 11px;
        padding: 0 18px;

        .logo {
          width: 40px;
          height: 40px;
          min-width: 40px;
          min-height: 40px;
          margin-top: 4px;
          margin-right: 12px;
        }

        .info {
          margin-top: 4px;

          h3 {
            max-width: 280px;
            font-size: 14px;
            font-weight: 400;
          }

          h3:hover {
            color: #00924b;
          }

          .type {
            font-size: 12px;
            color: @gray;

            span {
              position: relative;
            }

            .el-divider--vertical:last-child {
              display: none;
            }
          }
        }
      }
    }

    li:nth-of-type(3n) {
      margin-right: 0;
    }

    li:hover {
      transform: translateY(-6px);
      border: 2px solid #FAD49E;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    }
  }
}
</style>