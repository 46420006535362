<template>
  <div class="company">
    <ul class="list">
      <li class="box" v-for="item in list" :key="item.id">
        <div class="top">
          <h3 class="cursorP" @click="goRouter('/job/businessDetails','',item.comId)"><span class="line_clamp1">{{item.companyInfo.companyName}}</span> <img src="~/static/activity/jobFair-country/return_black.png" alt=""></h3>
          <ul class="child">
            <li class="line_clamp1">
              <img src="~/static/activity/jobFair-country/icon1.png" alt="">
              {{item.companyInfo.hyTop}}
            </li>
            <li class="line_clamp1">
              <img src="~/static/activity/jobFair-country/icon2.png" alt="">
              {{item.companyInfo.mun}}
            </li>
            <li class="line_clamp1">
              <img src="~/static/activity/jobFair-country/icon3.png" alt="">
              {{item.companyInfo.pr}}
            </li>
            <li class="line_clamp1">
              <img src="~/static/activity/jobFair-country/icon4.png" alt="">
              <a :href="website(item.companyInfo.website)" target="_blank">{{website(item.companyInfo.website)}}</a>
            </li>
          </ul>
        </div>
        <div class="bottom">
          <ul class="job_list">
            <li v-for="n in item.jobs.slice(0,3)" :key="n.id" class="cursorP" @click="goRouter('/job/jobDetail',n.jobId)">
                <h4 class="line_clamp1">
                  {{n.jobName}}
                </h4>
              <div class="money">
                {{n.salary}}
              </div>
            </li>
          </ul>
          <div class="more cursorP" v-if="item.jobs.length > 2"  @click="goRouter('/job/businessDetails','',item.comId,1)">
            查看该企业全部职位>>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "company",
  props: ['list'],
  data() {
    return {}
  },
  created() {
  },
  methods: {
    goRouter(url, id,ids,active) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
          companyId:ids,
          active:active
        },
      });
      window.open(href, "_blank");
    },
    website(url){
      if(url){
        return  url.substr(0,7).toLowerCase() == "http://" ? url : "http://" + url;
      }
    },
  },
}
</script>

<style scoped lang="less">
  .company{
    .list{
      display: flex;
      flex-wrap: wrap;
      .box{
        width: 384px;
        height: 348px;
        background: rgba(255, 255, 255, 0.5);
        border: 2px solid #FAD49E;
        margin-right: 24px;
        margin-top: 24px;
        padding: 18px;
        .top{
          padding-bottom: 24px;
          border-bottom:1px solid rgba(0, 18, 10, 0.05);
          h3{
            display: flex;
            span{
              flex: 1;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              color: #00120A;
            }
            img{
              margin-left: 26px;
              width: 18px;
              height: 18px;
            }
          }
          h3:hover{
            color: #28A46D;
          }
          .child{
            display: flex;
            flex-wrap: wrap;
            li{
              flex: 50%;
              color: #66716C;
              margin-top: 14px;
              img{
                vertical-align: middle;
                margin-right: 2px;
              }
            }
          }
        }
        .bottom{
          padding: 24px 18px;
          .job_list{
            li{
              display: flex;
              margin-bottom: 12px;
              h4{
                flex: 1;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #66716C;
              }
              h4:hover{
                color: #28A46D;
              }
              .money{
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FF9600;
              }
            }
          }
          .more{
            margin-top: 24px;
            text-align: center;
            color: #28A46D;
          }
        }
      }
      .box:nth-of-type(3n){
        margin-right: 0;
      }
    }
  }
</style>