<template>
  <div class="new">
    <div class="box">
      <div class="swiper">
        <el-carousel height="374px" class="swipers" @change="onChange($event,'new')">
          <el-carousel-item v-for="item in list" :key="item.id">
            <el-image
                :src="item.thumbnail"
                :alt="item.title"
                :title="item.title"
                class="banner"
            >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot">
                <img src="~/static/public/defaults.png" class="banner_err" alt="" style="height: 374px">
              </div>
            </el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="new">
        <ul class="list">
          <li v-for="(item,index) in list" :key="index" @click="goRouter('/news/newDetail',item.id)" v-show="active == index">
           <div class="top">
             <h3 class="line_clamp2">{{ item.title }}</h3>
           </div>
            <div class="text line_clamp4">
               {{item.conAbstract}}
           </div>
            <p>
              <span><img src="~/static/activity/jobFair-country/time.png" alt="">{{item.createTime}}</span>
              <span><img src="~/static/activity/jobFair-country/eye.png" alt="">{{item.reading}}</span>
              <span><img src="~/static/activity/jobFair-country/link.png" alt="">{{item.source || '中国残联就业'}}</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "new",
  props:['list'],
  data(){
    return{
      active: 0,
    }
  },
  methods:{
    onChange(val) {
      setTimeout(() =>{
        this.active = val;
      },500)
    },
    goRouter(url,id,active) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
          active:active
        },
      });
      window.open(href, "_blank");
    },
  }
}
</script>

<style scoped lang="less">
.box {
  display: flex;
  .swiper {
    width: 500px;
    height: 374px;
    display: flex;
    align-items: center;
    margin-right: 78px;
    background: #FAD49E;
    .swipers {
      width: 100%;
      transform: translate(36px,34px);
      img {
        width: 100%;
        height: 100%;
      }
      /deep/ .el-carousel__indicators .el-carousel__indicator {
        transition: all .6s;
      }

      /deep/ .el-carousel__indicators {
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

        .el-carousel__indicator .el-carousel__button {
          width: 12px;
          height: 4px;
          background: rgba(255, 255, 255, 0.6);
          border-radius: 3px;
          opacity: 1;
          transition: all .6s;
        }

        .is-active .el-carousel__button{
          width: 36px;
          background: white;
        }
      }
    }
  }
  .new{
    flex: 1;
    .title{
      display: flex;
      .more{
        flex: 1;
        line-height: 50px;
        text-align: right;
        margin-left: 15px;
        cursor: pointer;
        color: #66716C;

      }
      .more:hover{
        color: #D71216;
        text-decoration: underline;
      }
    }
    .list{
      li{
        cursor: pointer;
        .top{
          margin-top:90px;
          //padding-bottom: 12px;
          border-bottom: 1px solid rgba(0, 18, 10, 0.05);
        }
        h3{
          margin: 24px 0;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          line-height: 32px;
        }

        .text{
          padding-top: 30px;
          margin-bottom: 30px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 32px;
          text-indent: 2em;
          border-top: 1px solid rgba(255, 255, 255, 0.3);
        }
        p{
          display: flex;
          span{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin-right: 36px;
            img{
              vertical-align: middle;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}
</style>